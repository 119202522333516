// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Portfolio from './pages/Portfolio';
import Services from './pages/Services';
import Contact from './pages/Contact';
import WebDesign from './pages/WebDesign';
import SoftwareDevelopment from './pages/SoftwareDevelopment';
import ProductManagement from './pages/ProductManagement';
import GraphicDesign from './pages/GraphicDesign';
import Marketing from './pages/Marketing';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/web-design" element={<WebDesign />} />
        <Route path="/software-development" element={<SoftwareDevelopment />} />
        <Route path="/product-management" element={<ProductManagement />} />
        <Route path="/graphic-design" element={<GraphicDesign />} />
        <Route path="/marketing" element={<Marketing />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
