// src/components/Slider.js
import React, { useState } from 'react';
import './Slider.css';

const images = [
  'assets/img/portfolio/image1.png',
  'assets/img/portfolio/image4.png',
  'assets/img/portfolio/image3.png',
  'assets/img/portfolio/image2.png'
];

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="slider">
      <button className="slider-button prev" onClick={prevSlide}>❮</button>
      <img src={images[currentIndex]} alt="Portfolio" className="slider-image" />
      <button className="slider-button next" onClick={nextSlide}>❯</button>
    </div>
  );
};

export default Slider;
