import React from 'react';

const WebDesign = () => {
  return (
    <section id="service-details" className="service-details section">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-8">
            <h3>Web Design and Development</h3>
            <p>We offer comprehensive web design and development services, tailored to meet your unique needs. Our team of experts ensures that your website is not only visually appealing but also highly functional and user-friendly.</p>
            <ul>
              <li><i className="bi bi-check-circle"></i> <span>Custom design tailored to the client's brand.</span></li>
              <li><i className="bi bi-check-circle"></i> <span>Integrated payment gateway for seamless transactions.</span></li>
              <li><i className="bi bi-check-circle"></i> <span>User-friendly product management system.</span></li>
            </ul>
            <p>We employ a collaborative approach, working closely with you to understand your goals and deliver a product that exceeds your expectations.</p>
            <p>Whether you need a simple landing page or a complex e-commerce platform, our team has the expertise to deliver high-quality solutions that drive results. We are committed to providing exceptional service and support throughout the entire project lifecycle.</p>
          </div>
          <div className="col-lg-4">
            <img src="assets/img/services.jpg" alt="Web Design" className="img-fluid services-img" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default WebDesign;
