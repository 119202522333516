// src/pages/Home.js
import React, { useEffect } from 'react';
import AOS from 'aos';
import GLightbox from 'glightbox';
import 'aos/dist/aos.css';
import 'glightbox/dist/css/glightbox.css';
import 'swiper/swiper-bundle.css';

const Home = () => {
    useEffect(() => {
        // Initialize AOS for animations
        AOS.init({ duration: 1000 });

        // Initialize GLightbox for lightbox functionality
        const lightbox = GLightbox({
            selector: '.glightbox'
        });

        // Cleanup function to destroy instances when component unmounts
        return () => {
            lightbox.destroy(); // Ensures that the GLightbox instance is cleaned up
            AOS.refresh(); // Refresh AOS to ensure it doesn't retain outdated references
        };
    }, []);

    return (
        <div>
            {/* Hero Section */}
            <section id="hero" className="hero section">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center" data-aos="zoom-out">
                            <h1>Better Solutions for Your Business</h1>
                            <p>We are a team of talented designers dedicated to creating websites that prioritize your business in organic searches and enhance visibility for your clients</p>
                          
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="200">
                            <img src="assets/img/hero-img.png" className="img-fluid animated" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;
