// src/pages/About.js
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section id="about" className="about section">
      {/* Section Title */}

      <div className="container section-title" data-aos="fade-up">
        <h2 >About Us</h2>
      </div>
      {/* End Section Title */}

      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">

            <p>

              Welcome to Elshaddai Services LLC, your trusted partner for digital excellence.
              Based in Virginia, USA,
              we are a dynamic  dedicated to empowering businesses with innovative web solutions. Our core services encompass web design, web hosting, as well as comprehensive website management and maintenance.
            </p>
            <div class="col-lg-5 order-1 order-lg-2 why-us-img">
              <img src="assets/img/why-us.png" class="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="100"/>
            </div>
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
            <p>At Elshaddai Services, we understand that your website is the digital face of your business. That's why we strive to create visually appealing, highly functional websites that not only capture your brand's essence but also secure a top spot in organic search results. Our team of talented designers and tech experts are committed to ensuring your online presence is powerful and consistent.

Whether you're looking to launch a new website or optimize an existing one, Elshaddai Services
is here to guide you every step of the way. Let us help you transform your digital strategies into success stories!</p>

          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
