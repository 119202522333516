// src/pages/Portfolio.js
import React from 'react';

import Slider from '../components/Slider'; // Assuming this is a pre-configured Swiper slider replacement

const Portfolio = () => {
    return (
        <section id="portfolio-details" className="portfolio-details section">
            <div className="container" data-aos="fade-up" data-aos-delay="100">
                <div className="row gy-4">
                    <div className="col-lg-8">
                        <div className="portfolio-details-slider">
                            <Slider images={[
                                'assets/img/portfolio/image1.png',
                                'assets/img/portfolio/image2.png',
                                'assets/img/portfolio/image3.png',
                                'assets/img/portfolio/image4.png',
                                'assets/img/portfolio/image5.png'
                            ]} />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="portfolio-info" data-aos="fade-up" data-aos-delay="200">
                            <h2>Project Information</h2>
                            <h4>Elegant and Minimalist Blog Design</h4>
                        </div>
                        <div className="portfolio-description" data-aos="fade-up" data-aos-delay="300">
                            <p><strong>Features:</strong></p>
                            <ul>
                                <li>Elegant and minimalist design</li>
                                <li>Easy-to-use blogging platform</li>
                                <li>Social media integration</li>
                            </ul>
                            <p><strong>Technologies Used:</strong></p>
                            <ul>
                                <li>HTML, CSS, JavaScript</li>
                                <li>Gatsby</li>
                                <li>Contentful</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Portfolio;
