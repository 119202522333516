// src/pages/Services.js

import React from 'react';

const Services = () => {
    return (
        <section id="services" className="services section bg-light">
        <div className="container">
            <div className="section-title" data-aos="fade-up">
                <h2>Our Services</h2>
                <p>At ELSHADAI, we are dedicated to offering a wide range of specialized services designed to meet the diverse needs of our clients. Whether you're looking to launch a new product, optimize your operations,
                  or enhance your digital presence, our team of experts is here to guide you every step of the way. Here are some of the key services we offer:</p>
            </div>
            <div className="row gy-4">
                <div className="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                    <div className="service-item position-relative">
                        <div className="icon"><i className="bi bi-activity icon"></i></div>
                        <h3>Web Design and Development</h3>
                        <p>We offer comprehensive web design and development services, tailored to meet your unique needs. Our team ensures that your website is not only visually appealing but also highly functional and user-friendly.</p>
                        <ul>
                            <li><i className="bi bi-check-circle"></i> Custom design tailored to the client's brand.</li>
                            <li><i className="bi bi-check-circle"></i> Integrated payment gateway for seamless transactions.</li>
                            <li><i className="bi bi-check-circle"></i> User-friendly product management system.</li>
                        </ul>
                        <p>We employ a collaborative approach, working closely with you to understand your goals and deliver a product that exceeds your expectations.</p>
                        <p>Whether you need a simple landing page or a complex e-commerce platform, our team has the expertise to deliver high-quality solutions that drive results. We are committed to providing exceptional service and support throughout the entire project lifecycle.</p>
                    </div>
                </div>
                {/* Repeat for other services */}
                <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="200">
      <div class="service-item position-relative">
        <div class="icon"><i class="bi bi-bounding-box-circles icon"></i></div>
        <h4>Digital Marketing</h4>
        <p>Amplify your online presence with our comprehensive digital marketing services. We specialize in SEO, PPC, social media marketing, and content creation to help you reach your target audience effectively.</p>
      </div>
                 </div>
                 <div class="col-xl-3 col-md-6 d-flex" data-aos="fade-up" data-aos-delay="400">
                            <div class="service-item position-relative">
                              <div class="icon"><i class="bi bi-broadcast icon"></i></div>
                              <h4>onsulting Services</h4>
                              <p>Leverage our industry expertise to gain a competitive edge. Our consulting services cover market research, business strategy, and operational improvements.</p>
                            </div>
                          </div>
            </div>
        </div>
        </section>
    );
};

export default Services;
