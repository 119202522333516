import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'aos/dist/aos.css';
import 'glightbox/dist/css/glightbox.css';
import 'swiper/swiper-bundle.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header id="header" className="header d-flex align-items-center fixed-top">
      <div className="container-fluid container-xl position-relative d-flex align-items-center">
      <Link to="/" className="logo d-flex align-items-center me-auto">
<img src="assets/img/logo.png" alt="Elshaddai Services LLC" className="rounded-circle" style={{width: '100px', height: '100px'}} />
<h2>Elshaddai Services</h2>
</Link>
        <nav id="navmenu" className={`navmenu ${isOpen ? 'open' : ''}`}>
          <ul onClick={handleToggle} >
            <li><Link to="/" >Home</Link></li>
            <li><Link to="/about" >About</Link></li>
            <li><Link to="/services" >Services</Link></li>
            <li><Link to="/portfolio" >Portfolio</Link></li>
            <li><Link to="/contact" >Contact</Link></li>
          </ul>

          <i className="mobile-nav-toggle d-xl-none bi bi-list" onClick={handleToggle} > </i>
        </nav>

      </div>
    </header>
  );
};

export default Header;
