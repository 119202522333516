import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer id="footer" className="footer">
            <div className="container footer-top">
                <div className="row gy-4">
                    <div className="col-lg-4 col-md-6 footer-about">
                        <Link to="/hero" className="d-flex align-items-center">
                            <span className="sitename">Elshadai Services</span>
                        </Link>
                        <div className="footer-contact pt-3">
                            <p><strong>Phone:</strong> +1 2403559279</p>
                            <p><strong>Email:</strong> info@shaddaiwebcare.com</p>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-3 footer-links">
                        <h4>Useful Links</h4>
                        <ul>
                            <li><i className="bi bi-chevron-right"></i> <Link to="/home">Home</Link></li>
                            <li><i className="bi bi-chevron-right"></i> <Link to="/about">About us</Link></li>
                            <li><i className="bi bi-chevron-right"></i> <Link to="/service">Services</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-2 col-md-3 footer-links">
                        <h4>Our Services</h4>
                        <ul>
                            <li><i className="bi bi-chevron-right"></i> <Link to="/services/web-design">Web Design</Link></li>
                            <li><i className="bi bi-chevron-right"></i> <Link to="/services/web-development">Web Development</Link></li>
                            <li><i className="bi bi-chevron-right"></i> <Link to="/services/product-management">Product Management</Link></li>
                            <li><i className="bi bi-chevron-right"></i> <Link to="/services/marketing">Marketing</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <h4>Follow Us</h4>
                        <p>Join our community on social media to get the latest updates, behind-the-scenes content, and engage with our team. Follow us to be part of our journey and never miss out on any announcements!</p>
                    </div>
                </div>
            </div>

            <div className="container copyright text-center mt-4">
                <p>© <span>Copyright</span> <strong className="px-1 sitename">Elshadai Services LLC</strong> <span>All Rights Reserved</span></p>
            </div>
        </footer>
    );
};

export default Footer;
